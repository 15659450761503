import { useState,useEffect } from "react";
import { Select, Space, TimePicker,Modal, Button, notification } from "antd";
import "./WorkFlow.css";
import { HiOutlineUpload } from "react-icons/hi";
import { BsArrowLeftShort } from "react-icons/bs";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from "react-router-dom";

  const WorkFlow = () => {

    const [eventName, setEventName] = useState("");
    const [siteCategory, setSiteCategory] = useState();
    const [assignedTo, setAssignedTo] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [respondstartDate, setRespondStartDate] = useState(new Date());
    const [respondendDate, setRespondEndDate] = useState(new Date());
    const [siteData, setSiteData] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedStation, setSelectedStation] = useState(null);
    const [parameter, setParameter] = useState([]);
    const [selectedParameter, setSelectedParameter] = useState("");
    const [description, setDescription] = useState("");
    const [selectedMonitoringType, setSelectedMonitoringType] = useState("");
    const [monitoringTypes, setMonitoringTypes] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [saving, setSaving] = useState(false);


    const navigate = useNavigate();

    const { Option } = Select;

    useEffect(() => { 
      const fetchSiteData = async () => {
        try {
          const apiKey = process.env.REACT_APP_API_KEY;
          const response = await fetch("http://envicrafts.com:8080/sites/", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiKey}`, // Include your API key here
            },
          });
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          console.log("API Response:", data);
            setSiteData(data);
        } catch (error) {
          console.error("Error fetching site data", error);
        }
      };
      fetchSiteData();
    }, []);

    const handleSiteChange = (value) => {
      const site = siteData.find(site => site.name === value);
      setSelectedSite(site);
      setSelectedStation(null); // Clear selected station when site changes
    };


    const handleStationChange = (value) => {
      const station = selectedSite.stations.find(
        (station) => station.station === value
      );
      setSelectedStation(station);
      setParameter(station.parameters);
      setMonitoringTypes(station.monitoring_type);
    };


    const handleSave = async () => {

      if (!eventName || !selectedSite || !selectedStation || !siteCategory || !selectedMonitoringType || !selectedParameter || !assignedTo || !startDate || !endDate || !respondstartDate || !respondendDate || !description) {
        // If any field is empty, show the popup
        setPopupVisible(true);
        return; // Stop further execution
      }

      try {
        setSaving(true)
        const apiKey = process.env.REACT_APP_API_KEY;

        const response = await fetch("http://envicrafts.com:8080/workflow/workflows-post/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            eventName,
            siteId: selectedSite.site_id,
            siteCategory,
            monitorStation: selectedStation.station,
            selectedMonitoringType,
            parameter: selectedParameter,
            assignedTo,
            startDate,
            endDate,
            respondstartDate,
            respondendDate,
            description,
          }),
        });

        if (response.ok) {
          notification.success({
            message: "Workflow Saved",
            description: "New workflow added successfully.",
            duration: 3
          });
          console.log("Workflow saved successfully");
          navigate("/admin/workflowcompleted");

        } else {
          console.error("Failed to save workflow:", response.status);
        }

      } catch (error) {
        console.error("Error posting workflow data", error);
        // Handle error state or show error message to the user
      }finally {
        setSaving(false); // Set saving state back to false after processing is completed
      }
    };


    const back=()=>{
      navigate("/admin/workflowcompleted")
    }

  return (
    <div className="workflowpopup">
      <div className="workflow__header">
        <h1>Add New Workflow</h1>

        <button
        onClick={back}
        >

          <BsArrowLeftShort className="workflow__header__btn__icon" /> back
        </button>
      </div>
      <div className="workflow__popup__content">

        <input
          value={eventName}
          size="large"
          className="input-event"
          onChange={(e) => setEventName(e.target.value)}
          placeholder="Event Name"
        />

        <Select
          defaultValue="Site Name"
          style={{ width: "100%", fontSize: "2rem" }}
          size="large"
          className="dropdown"
          value={selectedSite ? selectedSite.name : "Site Name"}
          onChange={handleSiteChange}
        >
          {siteData.map((site) => (
            <Select.Option key={site.name} value={site.name}>
              {site.name}
            </Select.Option>
          ))}
        </Select>

        <Select
          defaultValue="Site category"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={siteCategory}
          size="large"
          className="dropdown"
          onChange={(value) => setSiteCategory(value)}
        >

        <Option value="Analyser Maintenance">Analyser Maintenance</Option>
        <Option value="Analyser Calibration">Analyser Calibration</Option>
        <Option value="Power Failure">Power Failure</Option>
        <Option value="Data Validation">Data Validation</Option>
        <Option value="Site Shutdown">Site Shutdown</Option>
        <Option value="Vendor Migration">Vendor Migration</Option>
        <Option value="Camera Maintenance">Camera Maintenance</Option>
        <Option value="Site Down">Site Down</Option>
        </Select>


        {selectedSite && selectedSite.stations && (
          <Select
            defaultValue="Stations"
            style={{ width: "100%", fontSize: "2rem" }}
            size="large"
            className="dropdown"
            value={selectedStation ? selectedStation.station : "Stations"}
            onChange={handleStationChange}
          >
            {selectedSite.stations.map((station) => (
              <Select.Option
                key={station.station}
                value={station.station}
              >
                {station.station}
              </Select.Option>
            ))}
          </Select>
        )}


{monitoringTypes && (
  <Select
    defaultValue="monitoring Type" // Assuming monitoringTypes is a string
    style={{ width: "100%", fontSize: "2rem" }}
    size="large"
    className="dropdown"
    onChange={setSelectedMonitoringType}
  >
    <Select.Option key={monitoringTypes} value={monitoringTypes}>
      {monitoringTypes}
    </Select.Option>
  </Select>
)}

        {parameter.length > 0 && (
          <Select
            placeholder="Parameter"
            mode="multiple"
            style={{ width: "100%", fontSize: "1rem" }}
            size="large"
            className="dropdown"
            onChange={setSelectedParameter}
          >
            {parameter.map((parameter) => (
              <Select.Option
                key={parameter}
                value={parameter}
              >
                {parameter}
              </Select.Option>
            ))}
          </Select>
        )}


<Select
        defaultValue="Assigned To"
        style={{
          width: "100%",
          fontSize: "2rem",
        }}
        size="large"
        className="dropdown"
        value={assignedTo} // Bind the value to the state
        onChange={(value) => setAssignedTo(value)} // Update the state when the value changes
        options={[
          {
            value: "All",
            label: "All",
          },
        ]}
      />
  
   

<Select
        defaultValue="Assigned To"
        style={{
          width: "100%",
          fontSize: "2rem",
        }}
        size="large"
        className="dropdown assignedfake"
        value={assignedTo} // Bind the value to the state
        onChange={(value) => setAssignedTo(value)} // Update the state when the value changes
        options={[
          {
            value: "All",
            label: "All",
          },
        ]}
      />
         

      <div className="date-time">
      <label>Start Date:</label>
      <DatePicker selected={startDate}
       onChange={(date) => setStartDate(date)}
       dateFormat="Pp"
       className="start-date-input"
       showTimeSelect
      />
      </div>
 
    <div className="date-time">
      <label> End Date:</label>
      <DatePicker selected={endDate}
       onChange={(date) => setEndDate(date)} 
        showTimeSelect
        className="end-date-input"
         dateFormat="Pp"
         />
    </div>

      <div className="date-time">
      <label>Time To Respond:</label>
      <DatePicker selected={respondstartDate}
       onChange={(date) => setRespondStartDate(date)}
       dateFormat="Pp"
       className="time-to-respond"
       showTimeSelect
       />
       </div>

      <div className="date-time">
      <label>Time To Action:</label>
      <DatePicker selected={respondendDate}
       onChange={(date) => setRespondEndDate(date)} 
        showTimeSelect
        className="time-to-action"
         dateFormat="Pp"
         />
       </div>
       </div>

       <textarea 
  type="text" 
  value={description}  // Make sure to bind it to the state
  onChange={(e) => setDescription(e.target.value)}
  placeholder="Enter work description" 
/>

       <Button
        className="workflow__popup__submitBtn centered-button"
        // onClick={() => {
        //   setHandlePopup(false);
        //   setOpen(true);
        // }}
        onClick={handleSave}
        loading={saving}
       >
        Save{" "}
        <HiOutlineUpload className="workflow__popup__submitBtn__icon" />
        </Button>

        <Modal
  title="Warning"
  visible={popupVisible}
  onCancel={() => setPopupVisible(false)}
  onOk={() => setPopupVisible(false)}
>
  Please fill in all fields before saving.
</Modal>
    </div>
  );
};

export default WorkFlow;


