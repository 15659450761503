// ExportPage.js

import React from "react";
import { Button } from "antd";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./UserSideWorkflow.css";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";

const ExportPage = ({ workflows, columns, exportType,siteName   }) => {


//   const exportToPDF = () => {
//     const pdfColumns = columns.map((col) => col.headerName);
//     const pdfRows = workflows.map((row) =>
//       columns.map((col) => String(row[col.field]))
//     );
  
//     const doc = new jsPDF('l', 'pt', 'letter'); // Set page orientation to landscape and use letter size
//     const pageSize = doc.internal.pageSize;
//     const pageWidth = pageSize.width || pageSize.getWidth();
//     const pageHeight = pageSize.height || pageSize.getHeight();
  
//     // Set font size and style
//     doc.setFontSize(12);
//     doc.setFont('helvetica', 'normal');
  
//     // Add the first page
//     doc.addPage();
  
//     // Draw a rectangle for the header background
//     doc.setFillColor(200, 200, 200); // Set the fill color
//     doc.rect(20, 20, pageWidth - 40, 30, 'F'); // Draw a filled rectangle
  
//     // Add header text
//     doc.setTextColor(0, 0, 0); // Set text color to black
//     doc.text('Workflows', pageWidth / 2, 40, 'center');
  
//     // Draw a rectangle for the table background
//     doc.setFillColor(255, 255, 255); // Set the fill color
//     doc.rect(20, 70, pageWidth - 40, pageHeight - 90, 'F'); // Draw a filled rectangle
  
//     doc.autoTable({
//       head: [pdfColumns],
//       body: pdfRows,
//       styles: {
//         fontSize: 10,
//         cellPadding: 2,
//         lineColor: [0, 0, 0],
//         lineWidth: 0.2,
//       },
//       margin: { top: 75 },
//       startY: 75,
//       theme: 'grid',
//     });
  
//     const pdfFileName = "workflows.pdf";
//     doc.save(pdfFileName);
//   };
  




const exportToPDF = () => {
 

    const pdfColumns = columns
    .filter((col) => col.field !== "ID" && col.field !== "Action")
    .map((col) => col.headerName);

  const pdfRows = workflows.map((row) =>
    columns
      .filter((col) => col.field !== "ID" && col.field !== "Action")
      .map((col) => String(row[col.field]))
  );

  const doc = new jsPDF('l', 'pt', 'letter');
  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.width || pageSize.getWidth();
  const pageHeight = pageSize.height || pageSize.getHeight();

  const companyName = "TechniCrafts Soft Solutions LLP";
  const additionalContent = "Online Work Flow Data System";
  const siteNameContent = `Report Generated for ${siteName} `;
  const companyFontSize = 18; // Adjust the font size for the company name
  const additionalContentFontSize = 14; // Adjust the font size for additional content
  const siteNameFontSize = 12;
  const currentDate = new Date();
  const currentTime = currentDate.toLocaleTimeString();

  // Add the company name at the top of the page
  doc.setFontSize(companyFontSize);
  doc.text(companyName, pageWidth / 2, 30, { align: 'center' });

  // Add additional content below the company name
  doc.setFontSize(additionalContentFontSize);
  doc.text(additionalContent, pageWidth / 2, 50, { align: 'center' });

  doc.setFontSize(siteNameFontSize)
  doc.text(siteNameContent, pageWidth / 2, 70, { align: 'center' });

  doc.setFontSize(8);
  doc.text(`Report generated on: ${currentDate}  ${currentTime}`, pageWidth / 2, 85, { align: 'center' });



  // Set font size and style
  doc.setFontSize(8);
  doc.setFont('helvetica', 'normal');

  const columnStyles = {};
  const columnWidths = [];
  columns
  .filter((col) => col.field !== "ID" && col.field !== "Action")
  .forEach((col) => {
    const widerColumnWidth = col.width * 2; // Adjust the multiplier based on your preference
    columnStyles[col.field] = {
      cellWidth: widerColumnWidth,
      fontStyle: 'bold',
      halign: 'center',
      fillColor: [200, 200, 200],
    };
    columnWidths.push(widerColumnWidth);
  });

  const headerStyles = { fontSize: 8, cellPadding: 2, fontStyle: 'bold', halign: 'center', fillColor: [150, 150, 150] };
  const bodyStyles = {
    textColor: [0, 0, 0], 
    fontSize: 6,
    cellPadding: 4,
    lineColor: [0, 0, 0],
    lineWidth: 0.1,
  };

  const bottomMargin = 20;
  const tableProps = {
    head: [pdfColumns],
    body: pdfRows,
    startY: 95,

    headStyles: {
        fontSize: 8,
        cellPadding: { top: 16, right: 4, bottom: 10, left: 4 },         fontStyle: 'bold',
        halign: 'center',
        fillColor: [150, 150, 150],
        rowHeight: 30, // Adjust the row height as needed
      },

    columnStyles,
    bodyStyles,
    columnWidth: columnWidths, // Set specific column widths
    theme: 'grid',
    headStyles: headerStyles,
    margin: { top: 10, right: 10, bottom: bottomMargin, left: 10 },
  };

  

  doc.autoTable(tableProps);

  const pdfFileName = `${siteName} workflow.pdf`;
  doc.save(pdfFileName);
};

const exportToExcel = () => {
    const excelColumns = columns
      .filter((col) => col.field !== "ID" && col.field !== "Action")
      .map((col) => ({ label: col.headerName, value: col.field }));

    const excelRows = workflows.map((row) =>
      columns
        .filter((col) => col.field !== "ID" && col.field !== "Action")
        .map((col) => String(row[col.field]))
    );

    const ws = XLSX.utils.aoa_to_sheet([excelColumns.map((col) => col.label), ...excelRows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    const excelFileName =  `${siteName} workflow.xlsx`;
    XLSX.writeFile(wb, excelFileName);
  };


  return (
    <div>
      <Button
        type="primary"
        icon={exportType === "excel" ? <DownloadOutlined /> : <FilePdfOutlined />}
        onClick={exportType === "excel" ? exportToExcel : exportToPDF}
        style={{ marginRight: "8px",  }}
        ghost
      >
        {exportType === "excel" ? " Excel " : " PDF "}
      </Button>
    </div>
  );
};

export default ExportPage;
