import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CameraFrame from './CameraFrame';
import CameraHistory from './CameraHistory';
import "./CameraStatus.css";
import CameraDetails from './CameraDetails';
import { useNavigate } from 'react-router-dom';
import { BsArrowLeftShort } from 'react-icons/bs';

const CameraPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const back=()=>{
    navigate("/admin/camerastream")
  }

  return (
    <Tabs selectedIndex={tabIndex} onSelect={handleTabChange} className="camerapage">
      <TabList>
        <Tab>Camera Frame</Tab>
        <Tab>Camera History</Tab>
        <button className='camerabackbtn' onClick={back}>
        <BsArrowLeftShort className='backbtnicon' />Back</button>
      </TabList>

      <TabPanel>
        <CameraFrame />
      </TabPanel>
      
      <TabPanel>
        <CameraHistory />
      </TabPanel>

    </Tabs>
  );
};

export default CameraPage;
