const userRole = localStorage.getItem("role");
export const BASE_LOGIN_URL = "http://envicrafts.com:8080/users/login/";

export const HEADERS = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + process.env.REACT_APP_API_KEY,
};

export const BASE_URL =
  userRole === "operator"
    ? "http://envicrafts.com:8080"
    : "http://envicrafts.com:8080";
    