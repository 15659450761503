import React from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Table = ({ medianReport, startDate, endDate, station }) => {
  const tableData = medianReport[0];
  const exportToExcel = () => {

    const worksheet = XLSX.utils.aoa_to_sheet([
      [
        "Parameter",
        "Average",
        "Minimum",
        "Maximum",
        "Median",
        "Mode",
        "Deviation",
      ],
    ]);

    // Add table data
    Object.entries(tableData).forEach(([parameter, values], index) => {
      const row = [
        parameter,
        values.average?.toString() || "",
        values.min?.toString() || "",
        values.max?.toString() || "",
        values.median?.toString() || "",
        values.mode?.toString() || "",
        values.deviation?.toString() || "",
      ];

      // Add row to the worksheet
      XLSX.utils.sheet_add_aoa(worksheet, [row], { origin: -1 });

      // Apply styles to the row
      const range = XLSX.utils.encode_range({
        s: { c: 0, r: index + 1 },
        e: { c: 6, r: index + 1 },
      });
      worksheet["!rows"] = worksheet["!rows"] || [];
      worksheet["!rows"][index + 1] = { hpt: 15, hpx: 15 };
      for (let col = 0; col < 7; col++) {
        const cellAddress = XLSX.utils.encode_cell({ c: col, r: index + 1 });
        worksheet[cellAddress] = Object.assign({}, worksheet[cellAddress], {
          s: {
            border: {
              top: { style: "thin" },
              bottom: { style: "thin" },
              left: { style: "thin" },
              right: { style: "thin" },
            },
          },
        });
      }
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "table_data.xlsx");
  };
  const exportToPDF = () => {
    const doc = new jsPDF();

    // Load the logo image
    // Set the company name and additional content
    const additionalContent = `Report Generated for Station ${station} from ${startDate} to ${endDate}`;
    const companyName = "TechniCrafts Soft Solutions LLP";
    // Calculate the height occupied by the additional content
    const additionalContentHeight =
      doc.getTextDimensions(additionalContent).h + 5;

    // Set the font size and color for the additional content
    doc.setFontSize(12);
    doc.setTextColor(128, 128, 128); // Gray color
    doc.text(additionalContent, doc.internal.pageSize.getWidth() / 2, 60, {
      align: "center",
    });

    // Add table data
    const tableRows = Object.entries(tableData).map(([parameter, values]) => {
      return [
        parameter,
        values.average,
        values.min,
        values.max,
        values.median,
        values.mode,
        values.deviation,
      ];
    });

    doc.autoTable({
      head: [
        [
          "Parameter",
          "Average",
          "Minimum",
          "Maximum",
          "Median",
          "Mode",
          "Deviation",
        ],
      ],
      body: tableRows,
      startY: 80 + additionalContentHeight, // Adjust the starting position of the table
    });

    // Add the footer to all pages
    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i);
      const pageHeight = doc.internal.pageSize.getHeight();
      const pageWidth = doc.internal.pageSize.getWidth();

      // Set the font size and color for the footer
      doc.setFontSize(10);
      doc.setTextColor(128, 128, 128); // Gray color

      // Add the centered footer with the company name
      doc.setFont("helvetica", "italic");
      doc.text(companyName, pageWidth / 2, pageHeight - 10, {
        align: "center",
      });
    }
    doc.save("median_median_report.pdf");
  };

  return (
    <div className="table-container">
      {/* <div className="export-buttons">
        <button onClick={exportToExcel}>Export to Excel</button>
        <button onClick={exportToPDF}>Export to PDF</button>
      </div> */}
      <table className="data-table">
        <thead>
          <tr>
            <th>Parameter</th>
            <th>Average</th>
            <th>Minimum</th>
            <th>Maximum</th>
            <th>Median</th>
            <th>Mode</th>
            <th>Deviation</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(tableData).map(([parameter, values]) => {
            return (
              <tr key={parameter}>
                <td>{parameter}</td>
                <td>{values.average}</td>
                <td>{values.min}</td>
                <td>{values.max}</td>
                <td>{values.median}</td>
                <td>{values.mode}</td>
                <td>{values.deviation}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
