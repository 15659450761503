import React, { useState, useEffect } from "react";
import "./UserSideWorkflow.css";
import { Select, Space, DatePicker, TimePicker,Button } from "antd";
import { HiPlusSm } from "react-icons/hi";
import { IoEyeSharp } from "react-icons/io5";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import UserSideWorkflow from "./UserSideWorkflow";
import { Link } from "react-router-dom";
import Loader from "../../global/Loader/Loader";
import { DownloadOutlined } from "@ant-design/icons";

import ExportPage from "./ExportPage";

const UserSideWorkflowCompleted = () => {

  const [handlePopup, setHandlePopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [siteInfo, setSiteInfo] = useState({});

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {

    const fetchData = async () => {

      try {
        const siteId = localStorage.getItem("siteId");
        const apiKey = process.env.REACT_APP_API_KEY;

        // Fetch site information based on siteId
        const siteResponse = await fetch(
          `http://envicrafts.com:8080/sites/?site_id=${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );

        if (!siteResponse.ok) {
          throw new Error(`Failed to fetch site information: ${siteResponse.status}`);
        }

        const siteData = await siteResponse.json();

        if (siteData && siteData.length > 0) {
          // Assuming the API response is an array with a single object
          const siteInfo = siteData[0];
          setSiteInfo(siteInfo);
        } else {
          console.error("Invalid site data structure:", siteData);
        }

        // Fetch workflows based on siteId
        const workflowResponse = await fetch(
          `http://envicrafts.com:8080/workflow/getworkflows/?site_id=${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );

        if (!workflowResponse.ok) {
          throw new Error(`Failed to fetch workflows: ${workflowResponse.status}`);
        }

        const data = await workflowResponse.json();

        if (data && data.workflow) {
          // Set the mapped data to the state and mark loading as false
          setWorkflows(data.workflow);
          setLoading(false);
        } else {
          console.error("Invalid data structure:", data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  },[]);

  const columns = [

    { field: "sr", headerName: "sr", width: 100 },
    { field: "ID", headerName: "ID", width: 200, hide: true  },
    { field: "Action", headerName: "Action", width:80,  renderCell: (params) => (

      <Link to={{
        pathname: `/operator/usersideworkflowupdate/${params.id}`,
        state: { workflowData: params.data } 
      }}>
        <IoEyeSharp className="actioneye" />
      </Link>

    ), },
    { field: "siteName", headerName: "Site Name", width: 150 },
    { field: "eventName", headerName: "Event Name", width: 150 },
    { field: "City", headerName: "City", width: 110 },
    { field: "State", headerName: "State", width: 110 , editable: true,},
    { field: "monitorStation", headerName: "Monitor Station", width: 160 },
    { field: "selectedMonitoringType", headerName: "Monitoring Type", width: 120 },
    { field: "siteCategory", headerName: "Site Category", width: 160 },
    { field: "startDate", headerName: "Start Date", width: 160 },
    { field: "endDate", headerName: "End Date", width: 160 },
    { field: "Status", headerName: "Status", width: 160 },
    { field: "assignedTo", headerName: "Assigned To", width: 160 },
    { field: "Last Acted By", headerName: "Last Acted By", width: 160 },
    { field: "Visibility", headerName: "Visibility", width: 160 },
    { field: "Description", headerName: "Description", width: 160 },
    // Add more fields as needed
  ];

  const getRowId = (row) => row.ID;

  let serialNumber = 1;

  const rows = workflows.slice(0, 1000).map((workflow) => ({
    sr: serialNumber++, // Increment serialNumber for each row
    ...workflow,
    siteName: siteInfo.name,
  }));

//  const rows = workflows.slice(0, 10).map((workflow) => ({
//   ...workflow,
//   // Use the ID field from the API response instead of the generated ID
//   ID: workflow.ID,
//   siteName: siteInfo.name,
// }));

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

 
  return (

    <div className="workflow">
      <div className="workflow__header">
        <h1>WorkFlow</h1>
        {/* <span style={{ color: "red" }}>(Page under developnment)</span> */}
        <button onClick={() => setHandlePopup(true)}>
         <Link to="/operator/usersideworkflow"> <HiPlusSm /> Add new Workflow </Link>
        </button>

      </div>

      {/* <div style={{ height: "450px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div> */}

        {loading ? (
        <p><Loader /></p>
         ) : (
        <div style={{ height: "450px" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            components={{
              Toolbar: (props) => (
                <GridToolbarContainer>
                  {/* <GridToolbarColumnsButton /> */}
                  <GridToolbarFilterButton />

                  <ExportPage
          workflows={rows} // Pass your rows or data array
          columns={columns} // Pass your columns
          exportType="excel"


        />
        <ExportPage
          workflows={rows} // Pass your rows or data array
          columns={columns} 
          // Pass your columns
          exportType="pdf"
          siteName={siteInfo.name}
          className="exportexcel"
         />

                </GridToolbarContainer>
              ),
            }}
            getRowId={getRowId}
            disableColumnSelector={true} 
            disableDensitySelector
          />
        </div>
         )}

      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseAlert}>
        <Alert
          // onClose={handleCloseAlert}
          severity="success"
          sx={{ width: "100%" }}
        >
          New workflow added successfully
        </Alert>
      </Snackbar>

      {handlePopup && (
  <UserSideWorkflow
    setOpen={setOpen}
    setHandlePopup={setHandlePopup}
  />
)}

{/* <ExportPage workflows={workflows} columns={columns} /> */}

    </div>
  );
};

export default UserSideWorkflowCompleted;
