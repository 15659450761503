import { useState,useEffect } from "react";
import { Select,notification,Modal,Button } from "antd";
import "./UserSideWorkflow.css";
import { HiOutlineUpload } from "react-icons/hi";
import { BsArrowLeftShort } from "react-icons/bs";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";

const UserSideWorkflow = () => {

  const [eventName, setEventName] = useState();
  const [siteCategory, setSiteCategory] = useState();
  const [assignedTo, setAssignedTo] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [respondstartDate, setRespondStartDate] = useState(new Date());
  const [respondendDate, setRespondEndDate] = useState(new Date());
  const [siteData, setSiteData] = useState({});
  const [selectedStation, setSelectedStation] = useState("");
  const [selectedParameter, setSelectedParameter] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMonitoringType, setSelectedMonitoringType] = useState("");
  const [selectAllParameters, setSelectAllParameters] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {

    const fetchSiteData = async () => {

      try {
        const siteId = localStorage.getItem("siteId");
        const apiKey =  process.env.REACT_APP_API_KEY;
        const response = await fetch(
          `http://envicrafts.com:8080/sites/?site_id=${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch site data: ${response.status}`);
        }

        const data = await response.json();

        // Assuming the API response is an array with a single object
        const siteInfo = data[0];

        // Update state with the fetched site information
        setSiteData(siteInfo);

        if (siteInfo?.stations && siteInfo.stations.length > 0) {
          setSelectedStation(siteInfo.stations[0].station);
          const defaultParameter =
            siteInfo.stations[0].parameters &&
            siteInfo.stations[0].parameters.length > 0
              ? siteInfo.stations[0].parameters[0]
              : "";
          setSelectedParameter(defaultParameter);
        }

        const defaultMonitoringType =
        siteInfo?.stations &&
        siteInfo.stations.length > 0 &&
        siteInfo.stations[0].monitoring_type
          ? siteInfo.stations[0].monitoring_type
          : "";
      setSelectedMonitoringType(defaultMonitoringType);
      } catch (error) {
        console.error("Error fetching site information:", error);
      }
    };

    fetchSiteData();
  }, []); 

  const { Option } = Select;

  const handleSave = async () => {

    if (!eventName || !selectedStation || !siteCategory || !selectedMonitoringType || !selectedParameter || !assignedTo || !startDate || !endDate || !respondstartDate || !respondendDate || !description) {
      // If any field is empty, show the popup
      setPopupVisible(true);
      return; // Stop further execution
    }

    try {
      setSaving(true)
      const apiKey = process.env.REACT_APP_API_KEY;
      const siteId = localStorage.getItem("siteId");

      const formData = {
        eventName,
        siteId,
        siteCategory,
        monitorStation: selectedStation,
        selectedMonitoringType,
        parameter: selectedParameter,
        assignedTo,
        startDate,
        endDate,
        respondstartDate,
        respondendDate,
        description,
      };


      const response = await fetch(
        "http://envicrafts.com:8080/workflow/workflows-post/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        notification.success({
          message: "Workflow Saved",
          description: "New workflow added successfully.",
          duration: 3
        });
        console.log("Workflow saved successfully");
        navigate("/operator/usersideworkflowcompleted");
        
      } else {
        console.error("Failed to save workflow:", response.status);
      }
    } catch (error) {
      console.error("Error while saving workflow:", error);
    }
    finally {
      setSaving(false); // Set saving state back to false after processing is completed
    }
  };

  const back=()=>{
    navigate("/operator/usersideworkflowcompleted")
  }

  const handleSelectAllParameters = () => {
    setSelectAllParameters((prev) => !prev);

    if (!selectAllParameters) {
      const allParameters = siteData.stations
        .find((station) => station.station === selectedStation)
        ?.parameters || [];
      setSelectedParameter(allParameters);
    } else {
      setSelectAllParameters(false);
      setSelectedParameter([]);
    }
  };  
  
  const handleParameterSelection = (value) => {
    setSelectAllParameters(false);
    setSelectedParameter(value);
  };


  return (
    <div className="workflowpopup">

      <div className="workflow__header">
        <h1>Add New Workflow</h1>
        <button onClick={back} >
          <BsArrowLeftShort className="workflow__header__btn__icon" /> back
        </button>
      </div>
      <div className="workflow__popup__content">

        <input
          value={eventName}
          size="large"
          className="input-event"
          onChange={(e) => setEventName(e.target.value)}
          placeholder="Event Name"
        />

      <Select
        defaultValue="Site Name"
        style={{
          width: "100%",
          fontSize: "2rem",
        }}
        size="large"
        className="dropdown"
        value={siteData.name} 
      >

        <Select.Option >{siteData.name}</Select.Option >
      </Select>

        <Select
          defaultValue="Site category"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          value={siteCategory}
          size="large"
          className="dropdown"
          onChange={(value) => setSiteCategory(value)}
        >

        <Option value="Analyser Maintenance">Analyser Maintenance</Option>
        <Option value="Analyser Calibration">Analyser Calibration</Option>
        <Option value="Power Failure">Power Failure</Option>
        <Option value="Data Validation">Data Validation</Option>
        <Option value="Site Shutdown">Site Shutdown</Option>
        <Option value="Vendor Migration">Vendor Migration</Option>
        <Option value="Camera Maintenance">Camera Maintenance</Option>
        <Option value="Site Down">Site Down</Option>
        <Option value="Parameter Exceedance">Parameter Exceedance</Option>
        <Option value="Configuration Update">Configuration Update</Option>

        </Select>



       {siteData?.stations && (
        <Select
          defaultValue="Stations"
          style={{
            width: "100%",
            fontSize: "2rem",
            // Adjust the margin as needed
          }}
          size="large"
          className="dropdown"
          onChange={(value) => setSelectedStation(value)}
        >
          {/* Render options based on the fetched stations */}
          {siteData.stations.map((station) => (
            <Select.Option key={station.station} value={station.station}>
              {station.station}
            </Select.Option>
          ))}
        </Select>
      )}


         {siteData?.stations && (
        <Select
          defaultValue="Monitoring Type"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          size="large"
          className="dropdown"
          onChange={(value) => setSelectedMonitoringType(value)}
        >
          {/* Render options based on the fetched monitoring types */}
          {siteData.stations.map((station) => (
            <Select.Option
              key={station.monitoring_type}
              value={station.monitoring_type}
            >
              {station.monitoring_type}
            </Select.Option>
          ))}
        </Select>
      )}


        {siteData?.stations && selectedStation && (
        <Select
          placeholder="Parameters"
          mode="multiple"
          style={{
            width: "100%",
            fontSize: "0.9rem",
          }}
          size="large"
          className="dropdown"
          onChange={(value) => handleParameterSelection(value)}
          value={selectedParameter}
        >
          {/* Checkbox for selecting all parameters */}
          <Select.Option key="selectAll" value="selectAll" disabled={true}>
            <input
              type="checkbox"
              onChange={() => handleSelectAllParameters()}
              checked={selectAllParameters}
              className="parameter-checkbox"
              
            />{" "}
          Select All
          </Select.Option>

          {siteData.stations
            .find((station) => station.station === selectedStation)
            ?.parameters.map((parameter) => (
              <Select.Option key={parameter} value={parameter}>
                {parameter}
              </Select.Option>
            ))}
        </Select>
      )}


      <Select
        defaultValue="Assigned To"
        style={{
          width: "100%",
          fontSize: "2rem",
        }}
        size="large"
        className="dropdown"
        value={assignedTo} // Bind the value to the state
        onChange={(value) => setAssignedTo(value)} // Update the state when the value changes
      >     <Option value="All">All</Option>
      </Select>
         <Select
          defaultValue="Assigned To"
          style={{
            width: "100%",
            fontSize: "2rem",
          }}
          size="large"
          className="dropdown assignedfake"
          options={[
            {
              value: "All",
              label: "All",
            },
          ]}
        />

      <div className="date-time">
      <label>Start Date:</label>
      <DatePicker selected={startDate}
       onChange={(date) => setStartDate(date)}
       dateFormat="Pp"
       className="start-date-input"
       showTimeSelect
      />
      </div>
 
    <div className="date-time">
      <label> End Date:</label>
      <DatePicker selected={endDate}
       onChange={(date) => setEndDate(date)} 
        showTimeSelect
        className="end-date-input"
         dateFormat="Pp"
         />
    </div>

      <div className="date-time">
      <label>Time To Respond:</label>
      <DatePicker selected={respondstartDate}
       onChange={(date) => setRespondStartDate(date)}
       dateFormat="Pp"
       className="time-to-respond"
       showTimeSelect
       />
       </div>

      <div className="date-time">
      <label>Time To Action:</label>
      <DatePicker selected={respondendDate}
       onChange={(date) => setRespondEndDate(date)} 
        showTimeSelect
        className="time-to-action"
         dateFormat="Pp"
         />
       </div>
       </div>

       <textarea 
  type="text" 
  value={description}  // Make sure to bind it to the state
  onChange={(e) => setDescription(e.target.value)}
  placeholder="Enter work description" 
/>

       <Button
        className="workflow__popup__submitBtn"
        onClick={handleSave}
        loading={saving}
       >
        Save{" "}
        <HiOutlineUpload className="workflow__popup__submitBtn__icon" />
        </Button>

        <Modal
  title="Warning"
  visible={popupVisible}
  onCancel={() => setPopupVisible(false)}
  onOk={() => setPopupVisible(false)}
>
  Please fill in all fields before saving.
</Modal>

    </div>
  );
};

export default UserSideWorkflow;







