import React from "react";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { siteSummaryApi } from "../../Store/Slices/siteSummarySlice";
import UserSiteStatus from "./UserSiteStatus";

const UserSiteStatusWrapper = () => {
  const siteId = localStorage.getItem("siteId");

  return (
    <ApiProvider api={siteSummaryApi}>
      <UserSiteStatus siteId={siteId} />
    </ApiProvider>
  );
};

export default UserSiteStatusWrapper;
