// utils/sessionUtils.js
export const setSession = (expirationHours = 24) => {
    const expirationTime = new Date().getTime() + expirationHours * 60 * 60 * 1000; // Convert hours to milliseconds
    localStorage.setItem("sessionExpiration", expirationTime);
    localStorage.setItem("login", "true");
  };
  
  export const isSessionExpired = () => {
    const expirationTime = localStorage.getItem("sessionExpiration");
    if (!expirationTime) return true;
    return new Date().getTime() > Number(expirationTime);
  };
  
  export const clearSession = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("username");
    localStorage.removeItem("userpassword");
    localStorage.removeItem("role");
    localStorage.removeItem("siteId");
    localStorage.removeItem("sessionExpiration");
  };
  