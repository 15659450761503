import React, { useState } from 'react';

const UserCameraHistory = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleFetch = () => {
    // Add your fetch logic here using startDate and endDate
    // For example, you can make an API call to get camera history data
    console.log('Fetching data from', fromDate, 'to', toDate);
  };

  return (
    <div>
    <div className='history'>
      <label className='history-label'>
        From Date:
        <input
          type="datetime-local"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
          className='historydateinput'
        />
      </label>
      <br />
      <label className='history-label'>
        To Date:
        <input
          type="datetime-local"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
          className='historydateinput'
        />
      </label>
      <br />
      </div>
      <button className="history-fetch-btn" onClick={handleFetch}>Fetch</button>
    </div>
  );
};

export default UserCameraHistory;


