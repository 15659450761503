import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "../../Pages/CameraStatus/CameraStatus.css";
import UserCameraFrame from './UserCameraFrame';
import UserCameraHistory from './UserCameraHistory';
import { useNavigate } from 'react-router-dom';


const UserCameraPage = () => {

  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (index) => {
    setTabIndex(index);
  };


  return (
    <Tabs selectedIndex={tabIndex} onSelect={handleTabChange} className="camerapage">
      <TabList>
        <Tab>Camera Frame</Tab> 
        <Tab>Camera History</Tab> 
      </TabList>

      <TabPanel>
        <UserCameraFrame /> 
      </TabPanel>

      <TabPanel>
        <UserCameraHistory /> 
      </TabPanel> 
    </Tabs> 
  );
};

export default UserCameraPage;

