import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { overallSummaryApi } from "../Store/Slices/overallSummarySlice";
import { siteSummaryApi } from "./Slices/siteSummarySlice";
import { generateReportApi } from "./Slices/generateReportSlice";
import { noficationsApi } from "./Slices/notificationSlice";

export const store = configureStore({

  reducer: {
    [overallSummaryApi.reducerPath]: overallSummaryApi.reducer,
    [siteSummaryApi.reducerPath]: siteSummaryApi.reducer,
    [generateReportApi.reducerPath]: generateReportApi.reducer,
    [noficationsApi.reducerPath]: noficationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(siteSummaryApi.middleware),
});

setupListeners(store.dispatch);

