import React, { useState, useEffect } from 'react';
import Loader from '../../global/Loader/Loader';
import { MdOutlineWaterDrop } from 'react-icons/md';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import "../../Pages/CameraStatus/CameraStatus.css";

const UserCameraDetails = () => {

  const [flowParameter, setFlowParameter] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [value, setValue] = useState('1');
  const [cameraDetails, setCameraDetails] = useState(null);
  const [siteDetails, setSiteDetails] = useState(null);
  const [industryDetails, setIndustryDetails] = useState(null);
  const [showIndustryDetails, setShowIndustryDetails] = useState(true);

  const RedLocationOnIcon = () => <LocationOnIcon style={{ color: 'red' }} />;
  const siteId = localStorage.getItem("siteId");

  useEffect(() => {

    const fetchData = async () => {

      try {
        // Fetch parameters
        const paramsResponse = await fetch(
          `http://envicrafts.com:8080/parameters/realtime-values/?site_id=${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }
        );

        if (!paramsResponse.ok) {
          throw new Error(`HTTP error! Status: ${paramsResponse.status}`);
        }

        const paramsResult = await paramsResponse.json();

        const flowParam = paramsResult.find(
          (param) =>
            param.parameter === 'Flow' || param.parameter === 'Flow Rate'
        );
        setFlowParameter(flowParam);

        // Fetch site details
        const siteResponse = await fetch(
          `http://envicrafts.com:8080/sites/?site_id=${siteId}`,

          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
            },
          }

        );

        if (!siteResponse.ok) {
          throw new Error(`HTTP error! Status: ${siteResponse.status}`);
        }

        const siteResult = await siteResponse.json();
        const site = siteResult[0]; // Assuming there's only one site with the given ID
        setLatitude(parseFloat(site.latitude));
        setLongitude(parseFloat(site.longitude));
        setSiteDetails(site);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
  }, [siteId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  useEffect(() => {
    const fetchData = async () => {

      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(`http://envicrafts.com:8080/sites/camera-details/?site_id=${siteId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${apiKey}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setCameraDetails(data[0]); // Assuming there is only one camera detail in the response
        } else {
          console.error('Failed to fetch camera details');
        }
      } catch (error) {
        console.error('Error fetching camera details:', error);
      }
    };

    fetchData();
  }, []); 

  useEffect(() => {
    if (cameraDetails) {
      setIndustryDetails({
        industryCategory: cameraDetails.site_category,
        industryName: cameraDetails.site_name,
        industryLocation: cameraDetails.location,
        monitoredArea: cameraDetails.location,
        cameraLocation: cameraDetails.site_city,
      });
    }
  }, [cameraDetails]); 


  return (
    <div className='camera-details'>
      {/* Buttons for zoom and move */}
      <div className='tabpanel'>
        <TabContext value={value}>
          <TabList
            onChange={handleChange}
            variant='scrollable'
            orientation='horizontal'
            aria-label='lab API tabs example'
          >
            <Tab label='Location' value='1' />
            <Tab label='Flow' value='2' />
            <Tab label="Details" value="3" />
          </TabList>

          <TabPanel value='1'>
            {latitude !== null && longitude !== null ? (
              <GoogleMap
                center={{ lat: latitude, lng: longitude }}
                zoom={12}
                mapContainerStyle={{ height: '229px', width: '330px' }}
              >
                {siteDetails && (
                  <MarkerF
                    key={siteDetails.station_id}
                    position={{
                      lat: parseFloat(siteDetails.latitude),
                      lng: parseFloat(siteDetails.longitude),
                    }}
                    title={siteDetails.name}
                    icon={<RedLocationOnIcon />}
                  />
                )}
              </GoogleMap>
            ) : (
              <Loader />
            )}
          </TabPanel>

          <TabPanel value='2'>       
            {flowParameter ? (
              <div>
                <div
                  className='realtimereport__livereading__card camera-flowchart'
                  key={flowParameter.id}
                >
                  <MdOutlineWaterDrop className='realtimereport__livereading__card__icon' />
                  <div className='realtimereport__livereading__card__content'>
                    <p className='flow-paragraph'>
                      <span>
                        {flowParameter.last_value} {flowParameter.unit}
                      </span>
                    </p>
                    <p className='realtimereport__livereading__card__content__parameterName'>
                      {flowParameter.parameter}
                    </p>
                    <p className='flow-paragraph'>{flowParameter.station}</p>
                    <p className='flow-paragraph'>
                      Limit : {flowParameter.normal_min} -{' '}
                      {flowParameter.normal_max} {flowParameter.unit}
                    </p>
                    <p className='flow-paragraph'>
                      Range : {flowParameter.today_min} -{' '}
                      {flowParameter.today_max}
                    </p>
                    <p className='flow-paragraph'>
                      Last Sync Date : {flowParameter?.last_sync?.slice(0, 10)}
                    </p>
                    <p className='flow-paragraph'>
                      Last Sync Time : {flowParameter?.last_sync?.slice(11, 16)}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </TabPanel>

          <TabPanel value='3'>

<div className="details-slider">
<div className="slider-header">
<button onClick={() => setShowIndustryDetails(true)}>&larr;</button>
<button onClick={() => setShowIndustryDetails(false)}>&rarr;</button>
</div>
<div className="details-container">
{showIndustryDetails ? (
<div className="industry-details">
  <h3>Industry Details</h3>
  <table>
    <tbody>
      <tr>
        <td>Industry Category:</td>
        <td>{industryDetails?.industryCategory}</td>
      </tr>
      <tr>
        <td>Industry Name:</td>
        <td>{industryDetails?.industryName}</td>
      </tr>
      <tr>
        <td>Industry Location:</td>
        <td>{industryDetails?.industryLocation}</td>
      </tr>
      <tr>
        <td>Monitored Area:</td>
        <td>{industryDetails?.monitoredArea}</td>
      </tr>
      <tr>
        <td>Camera Location:</td>
        <td>{industryDetails?.cameraLocation}</td>
      </tr>
    </tbody>
  </table>
</div>
) : (
<div className="camera-details-table">
<h3>Camera Details</h3>
<table>
  <tbody>
    <tr>
      <td>Model No:</td>
      <td>{cameraDetails?.model}</td>
    </tr>
    <tr>
      <td>PTZ:</td>
      <td>{cameraDetails?.ptz ? 'Yes' : 'No'}</td>
    </tr>
    <tr>
      <td>10x Zoom:</td>
      <td></td>
    </tr>
    <tr>
      <td>Night Vision:</td>
      <td></td>
    </tr>
    <tr>
      <td>Ip Camera:</td>
      <td>Yes</td>
    </tr>
  </tbody>
</table>
</div>
)}
</div>
</div>

</TabPanel>

        </TabContext>

       

  
       

      </div>
</div>
  );
};

export default UserCameraDetails;

