// // CameraFrameWithPTZButtons.js

// import React,{useEffect, useState} from 'react';
// import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
// import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from 'react-icons/fa';
// import "../../Pages/CameraStatus/CameraStatus.css";
// import UserCameraDetails from './UserCameraDetails';


// const UserCameraFrame = () => {
//     const siteId = localStorage.getItem("siteId");
//     const [cameraPosition, setCameraPosition] = useState({ tilt: 0, pan: 0, zoom: 1 });
//   // const [streamingUrlWithAuth, setStreamingUrlWithAuth] = useState('');
//   const [streamingUrl, setStreamingUrl] = useState('');
//   const apiKey = process.env.REACT_APP_API_KEY;
//   // const streamingUrl = 'http://localhost:3001/proxy/stream';
//   //const streamingUrl = 'http://localhost:60/camera/stream/';
// // const apiUrl = `http://192.168.1.124:6060/sites/cameraPTZ/?site_id=${siteId}`;
//  const apiUrl = `http://envicrafts.com:8080/sites/cameraPTZ/?site_id=${siteId}`;
// //const streamingApiUrl= "http://192.168.1.124:6060/camera/stream/";
//  const streamingApiUrl = `http://envicrafts.com:8080/sites/camera/stream/?site_id=${siteId}`;


//   const handlePTZButtonClick = async (command) => {
//     try {
//       const apiKey = process.env.REACT_APP_API_KEY;

//       let deltaX = 0.0;
//       let deltaY = 0.0;

//       switch (command) {
//         case 'up':
//         deltaY = 0.1;
//         break;
//       case 'down':
//         deltaY = -0.1;
//         break;
//       case 'right':
//         deltaX = 0.1;
//         break;
//       case 'left':
//         deltaX = -0.1;
//           break;
//         case 'zoomin':
//           deltaX = 0.2;
//           break;
//         case 'zoomout':
//           deltaX = -0.2;
//           break;
//         default:
//           console.warn('Unknown command:', command);
//           return;
//       }

//       const ptzData = {
//         [command]: command,
//         X: deltaX,
//         Y: deltaY,
//       };

//       console.log('PTZ Command Data:', ptzData);  // Log the data

//       const response = await fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//            Authorization: `Bearer ${apiKey}`,
//         },
//         body: JSON.stringify(ptzData),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const result = await response.json();
//       console.log('PTZ Command Result:', result);

//       setCameraPosition((prevPosition) => ({
//         ...prevPosition,
//         pan: prevPosition.pan + ptzData.X,
//         tilt: prevPosition.tilt + ptzData.Y,
//       }));

//     } catch (error) {
//       console.error('Error sending PTZ command:', error);
//     }
//   };


//   return (
//     <div className='camera-frame'>
//      <UserCameraDetails />

//       <iframe
//         title='Camera Stream'
//         className='webcam'
//         src={streamingApiUrl}
//         // src={streamingUrl}
//         frameBorder='0'
//         allowFullScreen
//         style={{ width: '1200px', height: '400px', overflow: 'hidden', marginLeft:"20px" }}
//         // style={{ width: '1900px', height: '1080px', overflow: 'hidden', marginLeft:"20px" }}

//       ></iframe>

//       <div className='ptz-control-buttons'>
//         <div className="grid-container">
//           <button className="ptz-button" onClick={() => handlePTZButtonClick('zoomin')}><FiZoomIn className='zoom' /></button>
//           <button className="ptz-button" onClick={() => handlePTZButtonClick('up')}><FaArrowUp /></button>
//           <button className="ptz-button" onClick={() => handlePTZButtonClick('zoomout')}><FiZoomOut className='zoom' /></button>
//           <button className="ptz-button" onClick={() => handlePTZButtonClick('left')}><FaArrowLeft /></button>
//           <button className="ptz-button" onClick={() => handlePTZButtonClick('down')}><FaArrowDown /></button>
//           <button className="ptz-button" onClick={() => handlePTZButtonClick('right')}><FaArrowRight /></button>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default UserCameraFrame;







import React, { useEffect, useState } from 'react';
import { FiZoomIn, FiZoomOut } from 'react-icons/fi';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from 'react-icons/fa';
import "../../Pages/CameraStatus/CameraStatus.css";
import UserCameraDetails from './UserCameraDetails';

const UserCameraFrame = () => {
    const siteId = localStorage.getItem("siteId");
    const [cameraPosition, setCameraPosition] = useState({ tilt: 0, pan: 0, zoom: 1 });
    const [speed, setSpeed] = useState(0.1); // Default speed
    const apiKey = process.env.REACT_APP_API_KEY;
    const apiUrl = `http://envicrafts.com:8080/sites/cameraPTZ/?site_id=${siteId}`;
    const [streamingApiUrl, setStreamingApiUrl] = useState('');
    const [iframeDimensions, setIframeDimensions] = useState({ width: '300px', height: '200px' });

    const handlePTZButtonClick = async (command) => {
        try {
            const ptzData = {
                [command]: command,
                X: 0, // Default value for X axis
                Y: 0, // Default value for Y axis
            };

            switch (command) {
                case 'up':
                    ptzData.Y = speed;
                    break;
                case 'down':
                    ptzData.Y = -speed; // Negative value for moving down
                    break;
                case 'right':
                    ptzData.X = speed;
                    break;
                case 'left':
                    ptzData.X = -speed; // Negative value for moving left
                    break;
                case 'zoomin':
                    ptzData.X = speed;
                    break;
                case 'zoomout':
                    ptzData.X = -speed;
                    break;
                default:
                    console.warn('Unknown command:', command);
                    return;
            }

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${apiKey}`,
                },
                body: JSON.stringify(ptzData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            setCameraPosition((prevPosition) => ({
                ...prevPosition,
                pan: prevPosition.pan + ptzData.X,
                tilt: prevPosition.tilt + ptzData.Y,
            }));

        } catch (error) {
            console.error('Error sending PTZ command:', error);
        }
    };

    const handleSpeedChange = (speed) => {
        setSpeed(speed);
    };

    useEffect(() => {
        const updateStreamingUrl = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 720) {
                setStreamingApiUrl(`http://envicrafts.com:8080/sites/camera/stream/?site_id=${siteId}&width=300&height=200`);
                setIframeDimensions({ width: '300px', height: '200px' });
            } else {
                setStreamingApiUrl(`http://envicrafts.com:8080/sites/camera/stream/?site_id=${siteId}&width=850&height=350`);
                setIframeDimensions({ width: '850px', height: '350px' });
            }
        };
        updateStreamingUrl();
        window.addEventListener('resize', updateStreamingUrl);

        return () => {
            window.removeEventListener('resize', updateStreamingUrl);
        };
    }, [siteId]);

    return (
        <div className='camera-frame'>
         

            <iframe
                title='Camera Stream'
                className='webcam'
                src={streamingApiUrl}
                frameBorder='0'
                allowFullScreen
                scrolling='no'
                style={{ 
                    width: iframeDimensions.width, 
                    height: iframeDimensions.height, 
                    overflow: 'hidden', 
                    marginLeft: "20px", 
                    borderRadius: '20px', 
                    border: '1px solid black' 
                }}
            ></iframe>



            <div className='ptz-control-buttons'>



                <div className="grid-container">
                    <button className="ptz-button" onClick={() => handlePTZButtonClick('zoomin')}><FiZoomIn className='zoom' /></button>
                    <button className="ptz-button" onClick={() => handlePTZButtonClick('up')}><FaArrowUp /></button>
                    <button className="ptz-button" onClick={() => handlePTZButtonClick('zoomout')}><FiZoomOut className='zoom' /></button>
                    <button className="ptz-button" onClick={() => handlePTZButtonClick('left')}><FaArrowLeft /></button>
                    <button className="ptz-button" onClick={() => handlePTZButtonClick('down')}><FaArrowDown /></button>
                    <button className="ptz-button" onClick={() => handlePTZButtonClick('right')}><FaArrowRight /></button>
                </div>


                <div className="ptz-speed-dropdown">
                <select className="select-speed" id="speed" value={speed} onChange={(e) => handleSpeedChange(parseFloat(e.target.value))}>
                    <option value={0.1}>1</option>
                    <option value={0.2}>2</option>
                    <option value={0.3}>3</option>
                    <option value={0.4}>4</option>
                    <option value={0.5}>5</option>
                    <option value={0.6}>6</option>
                    <option value={0.7}>7</option>
                    <option value={0.8}>8</option>
                    <option value={0.9}>9</option>
                    <option value={1.0}>10</option>
                </select>
                <label htmlFor="speed">Speed</label>
            </div>

                
            </div>

            

            <UserCameraDetails />

        </div>
    );
};

export default UserCameraFrame;
