import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, HEADERS } from "../../api";

export const noficationsApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),

  endpoints: (builder) => ({
    getNotificationsData: builder.query({
      query: () => ({
        url: "notifications/site-notifications",
        method: "GET",
        headers: HEADERS,
      }),
    }),

    getUserCurrentSiteData: builder.query({
      query: (siteId) => ({
        url: `notifications/site-notifications/?site_id=${siteId}`,
        method: "GET",
        headers: HEADERS,
      }),
    }),
  }),
});

export const { useGetNotificationsDataQuery, useGetUserCurrentSiteDataQuery   } = noficationsApi;

// http://envicrafts.com:8080/notifications/site-notifications/?site_id=1001






