import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isSessionExpired, clearSession } from "./global/utils/sessionUtils";

const useSessionManagement = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to handle logout
    const handleLogout = () => {
      clearSession();
      navigate("/");
    };

    // Check session expiration on component mount
    if (isSessionExpired()) {
      handleLogout();
    }

    // Set up an interval to check session every 24 hours
    const intervalId = setInterval(() => {
      if (isSessionExpired()) {
        handleLogout();
      }
    }, 86400000); // 86400000 ms = 24 hours

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);
};

export default useSessionManagement;


