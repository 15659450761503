import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./UserSideNotifications.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const UserSideNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const siteId = localStorage.getItem("siteId");
        
        const response = await fetch(
          `http://envicrafts.com:8080/notifications/site-notifications/?site_id=${siteId}`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );
        
        const data = await response.json();
        console.log(response);
        console.log(data);

        if (response.ok) {
          const newNotifications = data.message;
          if (newNotifications.length > notifications.length) {
            // New notifications received, show snackbar
            setSnackbarOpen(true);
          }
          setNotifications(data.message);
        } else {
          setError(`Error: ${data.message}`);
        }
      } catch (error) {
        setError(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading notifications: {error}</p>;
  }

  const handleBellClick = () => {
    // For demonstration purposes, increment the notificationsCount
    setNotificationsCount(notificationsCount + 1);

    // Show the snackbar
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  return (
    <div className="notifications">
      <div className="notifications__container">
        <h1>Notifications</h1>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                className="notifications__tablist"
              >
                <Tab label="Email" value="1" className="notifications__tab" />
              </TabList>
            </Box>

            <TabPanel value="1" className="notifications__tabpanel">
            {notifications.map((item, index) => (
                  <div className="notifications__email__card" key={index}>
                    <span className="card__email">{item.Description}</span>
                    <p>{item.Verb}</p>
                    <p>{item.Timestamp}</p>
                    <p>{item.level}</p>
                  </div>
                ))}
            </TabPanel>
          </TabContext>
        </Box>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="info"
        >
          New message received!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default UserSideNotifications;
