// import * as React from "react";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import { useGetNotificationsDataQuery } from "../../Store/Slices/notificationSlice";
// import "./Notifications.css";
// import Loader from "../../global/Loader/Loader";
// import Topbar from "../../global/Topbar/Topbar";

// export default function Notifications() {
  
//   const [value, setValue] = React.useState("1");
//   const res = useGetNotificationsDataQuery();
//   console.log(res);

  
//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
  
//   if (res.isLoading) return <Loader />;

 

//   return (
//     <>
//      <Topbar userType="admin" notificationsCount={res.data ? res.data.length : 0} />
//     <div className="notifications">
//       <div className="notifications__container">
//       <h1>
//           Notifications
//         </h1>
//         <Box sx={{ width: "100%", typography: "body1" }}>
//           <TabContext value={value}>
//             <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//               <TabList
//                 onChange={handleChange}
//                 aria-label="lab API tabs example"
//                 className="notifications__tablist"
//               >
//                 <Tab label="Email" value="1" className="notifications__tab" />
//                 <Tab label="SMS" value="2" className="notifications__tab" />
//                 <Tab label="notification" value="3" className="notifications__tab" />

//               </TabList>
//             </Box>
            
//             <TabPanel value="1" className="notifications__tabpanel">
//               {res?.data.map((item) => {
//                 return (
//                   <div className="notifications__email__card">
//                     <span className="card__email">teamTC@technicrafts.com</span>
//                     <p>{item.verb}</p>
//                     <p>{item.description}</p>
//                     <p style={{ color: "#8b8888" }}>23 Dec, 2022 at 12:43</p>
//                   </div>
//                 );
//               })}
//             </TabPanel>

//             <TabPanel value="2" className="notifications__tabpanel">
//               No SMS
//             </TabPanel>
//           </TabContext>
//         </Box>
       
//       </div>
     
//     </div>
//     </>
//   );
// }




// import * as React from "react";
// import Box from "@mui/material/Box";
// import Tab from "@mui/material/Tab";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
// import { useGetNotificationsDataQuery } from "../../Store/Slices/notificationSlice";
// import "./Notifications.css";
// import Loader from "../../global/Loader/Loader";
// import Topbar from "../../global/Topbar/Topbar";


// // ... (import statements remain unchanged)

// export default function Notifications() {
//   const [value, setValue] = React.useState("1");
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const [paginationLinks, setPaginationLinks] = React.useState(null);

//   const res = useGetNotificationsDataQuery({ page: currentPage });
//   console.log(res);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   React.useEffect(() => {
//     if (res.data && res.data.length > 0) {
//       const links = res.data[0].links;
//       setPaginationLinks(links);
//     }
//   }, [res.data]);

//   if (res.isLoading) return <Loader />;

//   const notificationsData = Array.isArray(res.data) ? res.data : [];
//   const emailData = notificationsData.length > 0 ? notificationsData[0].emails : [];

//   // Calculate start and end indices for displaying 10 messages on the current page
//   const startIndex = (currentPage - 1) * 10;
//   const endIndex = Math.min(startIndex + 10, emailData.length);

//   return (
//     <>
//       <Topbar
//         userType="admin"
//         notificationsCount={emailData.length}
//       />
//       <div className="notifications">
//         <div className="notifications__container">
//           <h1>Notifications</h1>
//           <Box sx={{ width: "100%", typography: "body1" }}>
//             <TabContext value={value}>
//               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
//                 <TabList
//                   onChange={handleChange}
//                   aria-label="lab API tabs example"
//                   className="notifications__tablist"
//                 >
//                   <Tab
//                     label="Email"
//                     value="1"
//                     className="notifications__tab"
//                   />
//                   <Tab
//                     label="SMS"
//                     value="2"
//                     className="notifications__tab"
//                   />
//                   <Tab
//                     label="notification"
//                     value="3"
//                     className="notifications__tab"
//                   />
//                 </TabList>
//               </Box>
            
//               <TabPanel value="1" className="notifications__tabpanel">
//                 {emailData.slice(startIndex, endIndex).map((item) => (
//                   <div className="notifications__email__card" key={item.id}>
//                     <span className="card__email">{item.message.description}</span>
//                     <p>{item.level}</p>
//                     <p style={{ color: "#8b8888" }}>{item.date}</p>
//                   </div>
//                 ))}
//                 <div className="pagination">
//                   <button
//                     onClick={() => handlePageChange(currentPage - 1)}
//                     disabled={!paginationLinks?.previous}
//                   >
//                     Previous
//                   </button>
//                   <span>{`Page ${currentPage}`}</span>
//                   <button
//                     onClick={() => handlePageChange(currentPage + 1)}
//                     disabled={!paginationLinks?.next}
//                   >
//                     Next
//                   </button>
//                 </div>
//               </TabPanel>
//             </TabContext>
//           </Box>
//         </div>
//       </div>
//     </>
//   );
// }



import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useGetNotificationsDataQuery } from "../../Store/Slices/notificationSlice";
import "./Notifications.css";
import Loader from "../../global/Loader/Loader";
import Topbar from "../../global/Topbar/Topbar";

export default function Notifications() {
  const [value, setValue] = React.useState("1");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [paginationLinks, setPaginationLinks] = React.useState(null);
  const [slideDirection, setSlideDirection] =React.useState(null);

  const res = useGetNotificationsDataQuery({ page: currentPage });
  console.log(res);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  React.useEffect(() => {
    if (res.data && res.data.length > 0) {
      const links = res.data[0].links;
      setPaginationLinks(links);
    }
  }, [res.data]);

  if (res.isLoading) return <Loader />;

  const notificationsData = Array.isArray(res.data.message) ? res.data.message : [];

  const endIndex = Math.min((currentPage * 10), notificationsData.length);

  return (
    <>
      <Topbar
        userType="admin"
        notificationsCount={notificationsData.length}
      />
      <div className="notifications">
        <div className="notifications__container">
          <h1>Notifications</h1>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  className="notifications__tablist"
                >
                  <Tab
                    label="Email"
                    value="1"
                    className="notifications__tab"
                  />
                  <Tab
                  label="sms"
                  value="2"
                  />
                </TabList>
              </Box>
              <div className="main-page">
              <TabPanel value="1" className={`notifications__tabpanel ${slideDirection}`}>
                {notificationsData.slice(0, endIndex).map((item, index) => (
                  <div className="notifications__email__card" key={index}>
                    <span className="card__email">{item.Description}</span>
                    <p>{item.Verb}</p>
                    <p>{item.Timestamp}</p>
                    <p>{item.level}</p>
                  </div>
                ))}

              <div className="pagination">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    // disabled={!paginationLinks?.previous}k
                    disabled={currentPage === 1}
                  >
                    less 
                  </button>
                  <span>{`Page ${currentPage}`}</span>
                  <button 
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="next-page"
                  >
                    more 
                  </button> 
                </div>
              </TabPanel>

              </div>
              <TabPanel value="2">
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </>
  );
}
