import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react"; // import lazy and Suspense
import Login from "./Login/Login";
import Topbar from "./global/Topbar/Topbar";
import ForgotPassword from "./Login/ForgotPassword";

import Loader from "./global/Loader/Loader";
import ProtectedRoute from "./ProtectedRoute";
import StationStatusWrapper from "./Pages/StationStatus/StationStatusWrapper";
import NotFound from "./global/404/NotFound";
import NotificationsWrapper from "./Pages/Notifications/NotificationsWrapper";
import ParametersWrapper from "./Pages/Parameters/ParametersWrapper";
import WorkFlowNotAvaiable from "./Pages/WorkFlow/WorkFlowNotAvaiable";
import "./index.css";
import CameraStatus from "../src/Pages/CameraStatus/CameraStatus";
import PercentileReportWrapper from "./Pages/PercentileReport/PercentileReportWrapper";
import SiteStatusData from "./Pages/SiteStatus/SiteStatusData";
import SiteDetailsPage from "./Pages/SiteStatus/SiteDetailsPage";
import SiteDetailsPageWrapper from "./Pages/SiteStatus/SiteDetailsPageWrapper";
import UserSiteStatusWrapper from "./User/UserSiteStatus/UserSiteStatusWrapper";
import { Helmet } from "react-helmet";
import Notifications from "./Pages/Notifications/Notifications";
import UserSideNotificationsWrapper from "./User/UserSideNotifications/UserSideNotificationsWrapper";
import WorkFlowCompleted from "./Pages/WorkFlow/WorkFlowCompleted";
import UserSideWorkflowCompleted from "./User/UserSideWorkflow/UserSideWorkflowCompleted";
import UserSideWorkflow from "./User/UserSideWorkflow/UserSideWorkflow";
import Table from "./Pages/CustomReports/MedianReportDataTable";
import UserSideWorkflowUpdate from "./User/UserSideWorkflow/UserSideWorkflowUpdate";
import CameraPage from "./Pages/CameraStatus/CameraPage";
import UserCameraPage from "./User/UserCameraStatus/UserCameraPage";
import CameraStream from "../src/Pages/CameraStatus/CameraStatus";
import WorkFlow from "./Pages/WorkFlow/WorkFlow";
import WorkflowUpdate from "./Pages/WorkFlow/WorkFlowUpdate";
import useSessionManagement from "./useSessionManagement";


const AdminDashboard = lazy(() => import("./dashboard/AdminDashboard"));
const UserDashboard = lazy(() => import("./dashboard/UserDashboard"));
const MyAccount = lazy(() => import("./Pages/MyAccount/MyAccount"));
const CustomReport = lazy(() => import("./Pages/CustomReports/CustomReport"));
const SiteSummary = lazy(() => import("./Pages/SiteSummary/SiteSummary"));

const SiteStatusWrapper = lazy(() =>
  import("./Pages/SiteStatus/SiteStatusWrapper")
);
const MapViewWrapper = lazy(() => import("./Pages/MapView/MapViewWrapper"));
const Calibration = lazy(() => import("./Pages/Calibration/Calibration"));

function App() {

useSessionManagement();

  const [isAuthenticated, setIsAuthenticated] = useState();
  const [userInfo, setUserInfo] = useState();
  const [role, setRole] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const authenticated = localStorage.getItem("login") === "true";
    const role = localStorage.getItem("role");
    const userType = role === "admin" ? "admin" : "user";

    // Redirect to login if not authenticated
    if (!authenticated) {
         navigate("/");
    } else {
      // Check for role-based access
      if (
        (location.pathname.includes("/admin") && userType !== "admin") ||
        (location.pathname.includes("/user") && userType !== "user")
      ) {
        navigate("/");
      }
    }
  }, [navigate, location.pathname]);

  // Disable Topbar for particular pages

  const showTopbar =
    location.pathname !== "/" &&
    location.pathname !== "/forgotpassword" &&
    location.pathname !== "*";

  const userType = localStorage.getItem("role"); // For detecting Operator

  return (
    <div className="app">
      <Helmet>
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCjjs-zUXlRnvmGi4AbCxABHIqfydG0fow"
          async
        ></script>
      </Helmet>
      {showTopbar && <Topbar userType={userType} />}
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Login setUserInfo={setUserInfo} />
            </Suspense>
          }
        />

        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path={`/${userType}`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={AdminDashboard} />
            </Suspense>
          }
        />

          <Route
          path={"/admin/camerapage/:id"}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={CameraPage} />
            </Suspense>
          }
          />

          <Route
          path={"admin/camerastream"}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={CameraStream} />
            </Suspense>
          }
          />

         <Route
          path="/admin/calibration"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={Calibration} />
            </Suspense>
          }
        />

       

        <Route
          path="/admin/workflowcompleted"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={WorkFlowCompleted} />
            </Suspense>
          }
        />

            <Route
          path="/admin/workflow"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={WorkFlow} />
            </Suspense>
          }
        />

         <Route
          path={"/admin/workflowupdate/:id"}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={WorkflowUpdate} />
            </Suspense>
          }
          />


        <Route
          path={`/${userType}/myaccount`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={MyAccount} />
            </Suspense>
          }
        />

        <Route
          path={`/${userType}/site/site-details`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserSiteStatusWrapper} />
            </Suspense>
          }
        />

        <Route
          path="/user"
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserDashboard} />
            </Suspense>
          }
        />

          <Route
          path={`/${userType}/site/notifications`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserSideNotificationsWrapper} />
            </Suspense>
          }
        />

          <Route
          path={`/${userType}/usersideworkflowcompleted`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserSideWorkflowCompleted} />
            </Suspense>
          }
          />

           <Route
          path={`/${userType}/usersideworkflow`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserSideWorkflow} />
            </Suspense>
          }
          />

          <Route
          path={`/${userType}/usersideworkflowupdate/:id`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserSideWorkflowUpdate} />
            </Suspense>
          }
          />



        <Route
          path={`/${userType}/sitesummary`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={SiteSummary} />
            </Suspense>
          }
        />
    
        <Route
          path={`/${userType}/mapview`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={MapViewWrapper} />
            </Suspense>
          }
        />

        <Route
          path={`/${userType}/sitestatus`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={SiteStatusWrapper} />
            </Suspense>
          }
        />
       
        <Route
          path={`/${userType}/customreport`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={CustomReport} />
            </Suspense>
          }
        />

           <Route
          path={`/${userType}/medianreporttable`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={Table} />
            </Suspense>
          }
        />

        <Route
          path={`/${userType}/notifications`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={NotificationsWrapper} />
            </Suspense>
          }
        />

        <Route
          path={`/${userType}/stationstatus`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={StationStatusWrapper} />
            </Suspense>
          }
        />

        <Route
          path={`/${userType}/parametersummary`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={ParametersWrapper} />
            </Suspense>
          }
        />

        <Route
          path={`/${userType}/percentilereport`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={PercentileReportWrapper} />
            </Suspense>
          }
        />


        <Route
          path={`/${userType}/usercamerapage`}
          element={
            <Suspense fallback={<Loader />}>
              <ProtectedRoute Component={UserCameraPage} />
            </Suspense>
          }
        />

        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  );
}


export default App;
