import React, { useEffect, useState } from "react";
import "./WorkFlow.css";
import { Select, Space, DatePicker, TimePicker } from "antd";
import { HiPlusSm } from "react-icons/hi";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MuiAlert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";

const WorkFlowCompleted = () => {

  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch("http://envicrafts.com:8080/workflow/getworkflows/?site_id", {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch workflows");
        }
        const data = await response.json();
        // Assuming each row object has an "ID" field that can be used as the unique identifier
        const rowsWithIds = data.workflow.map((row, index) => ({ ...row, id: index + 1 }));
        setRows(rowsWithIds);
      } catch (error) {
        console.error("Error fetching workflows:", error);
      }
    };

    fetchWorkflows();
  }, []);


  const columns = [
    { field: "sr", headerName: "sr", width: 50 },
    { field: "ID", headerName: "ID", width: 200, hide: true  },
    { field: "Action", headerName: "Action", width:80,  renderCell: (params) => (
      <Link to={{
        pathname: `/admin/workflowupdate/${params.row.ID}`,
        state: { workflowData:params.row } 
      }}>
        <IoEyeSharp className="actioneye" />
      </Link>
    )},
    { field: "siteId", headerName: "Site Id", width: 80 },
    { field: "Name", headerName: "Site Name", width: 200 },
    { field: "City", headerName: "City", width: 150 },
    { field: "State", headerName: "State", width: 150 },
    { field: "monitorStation", headerName: "Monitor Station", width: 150 },
    { field: "selectedMonitoringType", headerName: "Monitoring Type", width: 150 },
    { field: "eventName", headerName: "Event Name", width: 180 },
    { field: "siteCategory", headerName: "Site Category", width: 180 },
    { field: "startDate", headerName: "Start Date", width: 180 },
    { field: "endDate", headerName: "End Date", width: 180 },
    { field: "Status", headerName: "Status", width: 150 },
    { field: "assignedTo", headerName: "Assigned To", width: 150 },
    { field: "Last Acted By", headerName: "Last Acted By", width: 200 },
    { field: "Description", headerName: "Description", width: 200 },
 
  ];

  return (
    <div className="workflow">
      <div className="workflow__header">
        <h1>WorkFlow</h1>
        <button>
          <Link to="/admin/workflow"><HiPlusSm /> Add new Workflow </Link>
        </button>
      </div>

      <div style={{ height: "450px" }}>
        <DataGrid
           rows={rows.map((row, index) => ({ ...row, sr: index + 1 }))}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </div>
    </div>
  );
};

export default WorkFlowCompleted;
