import React from "react";
import UserSideNotifications from "./UserSideNotifications"
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { noficationsApi } from "../../Store/Slices/notificationSlice";

const UserSideNotificationsWrapper = () => {
  return (
    <>
      <ApiProvider api={noficationsApi}>
        <UserSideNotifications />
      </ApiProvider>
    </>
  );
};
export default UserSideNotificationsWrapper;


