import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select,notification } from "antd";
import "./WorkFlow.css"
import { BsArrowLeftShort } from "react-icons/bs";

const WorkflowUpdate = () => {
  const { id } = useParams();
  const [workflowData, setWorkflowData] = useState(null);
  const [status, setStatus] = useState('');
  const [lastActedBy, setLastActedBy] = useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const [saving, setSaving] = useState(false);

  const { Option } = Select;
  const navigate = useNavigate();

  useEffect(() => {

    const fetchWorkflowUpdateData = async () => {
      try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const response = await fetch(`http://envicrafts.com:8080/workflow/getupdateworkflow/?Id=${id}`, {
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch workflow update data: ${response.status}`);
        }

        const data = await response.json();
        setWorkflowData(data); 

      } catch (error) {
        console.error("Error fetching update data:", error);
      }
    };

    fetchWorkflowUpdateData();
  }, [id]);

  const handleUpdate = async () => {
    try {
        setSaving(true)
      const apiKey = process.env.REACT_APP_API_KEY;
      const updateData = {
        Status: status,
        AssignedTo: assignedTo,
        LastActedBy: lastActedBy,
      };

      const response = await fetch(`http://envicrafts.com:8080/workflow/workflows-update/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        throw new Error(`Failed to update workflow: ${response.status}`);
      }
      console.log('Workflow updated successfully');
      notification.success({
        message: "Workflow Saved",
        description: "workflow Updated successfully.",
        duration: 3
      });
      // Navigate to UserSideWorkflowCompleted page
      navigate('/admin/workflowcompleted');
      // Handle success, e.g., show a success message or redirect
    } catch (error) {
      console.error('Error updating workflow:', error);
      // Handle error, e.g., show an error message
    }finally {
        setSaving(false); // Set saving state back to false after processing is completed
      }
  };

  const back=()=>{
    navigate("/admin/workflowcompleted")
  }

  return (
    <div className='workflow-update'>
      <button onClick={back} className="updatebackbtn" >

<BsArrowLeftShort className="workflow__header__btn__icon updatearrow" />back
</button>
      <h2>Work Flow Update</h2>
      {/* Render your update page content using the fetched workflowData */}
      {workflowData && (
        <div className='workflow-update-data' >

          <div className='workflow-disable-inputs'>

          <label className='update-label'>Site Name:
          <input className='update-input' value={workflowData[0].Name} disabled />
          </label>

          <label className='update-label' >City:</label>
          <input className='update-input' value={workflowData[0].City} disabled />

          <label className='update-label' >Event:</label>
          <input className='update-input' value={workflowData[0].eventName} disabled />

          <label className='update-label'>Start Date:</label>
          <input className='update-input' value={workflowData[0].startDate} disabled />

          <label className='update-label'>End Date:</label>
          <input className='update-input' value={workflowData[0].endDate} disabled />
          
          <div className='description'>
          <label className='update-label '>Description:</label>
          <Input.TextArea className='update-input' value={workflowData[0].Description} disabled />

           </div>
          </div>


          <div className='updating-inputs'>
            <label className='update-label'>Status:</label>
            <Select
              className='update-input status-input'
              value={status}
              onChange={(value) => setStatus(value)}
            >
              <Option value="Reopened">All</Option>
              <Option value="NotStarted">Not Started</Option>
              <Option value="InProgress">In Progress</Option>
              <Option value="Approved"  >Approved</Option>
              <Option value="Acknowledged">Acknowledged</Option>
              <Option value="Completed">Completed</Option>
              <Option value="Closed">Closed</Option>
              <Option value="Reopened">Reopened</Option>
            </Select>

          <label className='update-label'>Assigned To:</label>
          <Input
            className='update-input'
            value={assignedTo}
            onChange={(e) => setAssignedTo(e.target.value)}
          />

            <label className='update-label'>Last Acted By:</label>
            <Input
              className='update-input'
              value={lastActedBy}
              onChange={(e) => setLastActedBy(e.target.value)}
              // Assuming you want to capture Last Acted By input
            />

          <Button onClick={handleUpdate} loading={saving} className='workflow-update-btn'>Update</Button>

          </div>

        </div>
      )}

    </div>
  );
};

export default WorkflowUpdate;


